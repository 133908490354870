<template>
  <modal
      @close="$emit('close')"
      class="custom-popup order-details-popup"
  >
    <template slot="header">

      <div
          class="ws-initial"
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['logInfo_OrderDetails'])"></div>
        {{ $t('logInfo_OrderDetails.localization_value.value') }}
        <template v-if="trackingLogItem.tracking_number">
          {{ trackingLogItem.tracking_number }}
        </template>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="simple-table">
          <div class="simple-table__inner">

            <div v-if="noData">{{ $t('logInfo_noData.localization_value.value') }}</div>

            <table v-else>
              <tr>
                <th colspan="2">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['logInfo_Currently'])"></div>
                    {{ $t('logInfo_Currently.localization_value.value') }}
                  </div>
                </th>
              </tr>
              <tr v-if="trackingLog = getTypeTrackingLog(trackingLogItem)">
                <td>
                  <div class="site-status d-flex"
                        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([getTrackingTypeName(trackingLog.trackingType)])"></div>
                    <StatusIcoBtn :type="trackingLog.trackingType"/>
                    <span class="tracking-name">{{
                        $t(`${getTrackingTypeName(trackingLog.trackingType)}.localization_value.value`)
                      }}</span>
                  </div>
                </td>
                <td>
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_at'])"></div>
                    {{ trackingLog.trackingState }}
                    <template v-if="trackingLog.location">
                      {{ trackingLog.location }},
                    </template>
                    <template v-if="trackingLog.eventTime">
                      {{ trackingLog.eventTime | moment("DD MMM, YYYY") }}
                      {{ $t('common_at.localization_value.value') }}
                      {{ trackingLog.eventTime | moment("HH:mm") }}
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['logInfo_History'])"></div>
                    {{ $t('logInfo_History.localization_value.value') }}
                  </div>
                </th>
              </tr>
              <tr v-for="(item, index) in getTypeTrackingLogHistory(trackingLogItem)"
                  :key="index"
              >
                <td>
                  <div class="site-status d-flex"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([getTrackingTypeName(trackingLog.trackingType)])"></div>
                    <StatusIcoBtn v-if="item.trackingType" :type="item.trackingType"/>
                    <StatusIcoBtn v-if="!item.trackingType" :type="'no-info'"/>
                    <span class="tracking-name" v-if="item.trackingType">{{
                        $t(`${getTrackingTypeName(item.trackingType)}.localization_value.value`)
                      }}</span>
                  </div>
                </td>
                <td>{{ item.trackingState }}
                  <template v-if="item.location">
                    {{ item.location }},
                  </template>
                  <template v-if="item.event_time">
                    {{ item.event_time | moment("DD MMM, YYYY") }}
                    {{ $t('common_at.localization_value.value') }}
                    {{ item.event_time | moment("HH:mm") }}
                  </template>
                </td>
              </tr>
              <!--              <tr>-->
              <!--                <td>-->
              <!--                  <span class="site-status orange">-->
              <!--                    <StatusIcoBtn :type="'out-for-delivery'" />-->
              <!--                    <span>Out for Delivery</span>-->
              <!--                  </span>-->
              <!--                </td>-->
              <!--                <td>Good condition. Dormund 22 Aug 2020 at 6:41</td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td>-->
              <!--                  <span class="site-status blue">-->
              <!--                    <StatusIcoBtn :type="'in-transit'" />-->
              <!--                    <span>In Transit</span>-->
              <!--                  </span>-->
              <!--                </td>-->
              <!--                <td>Kiege Euro Hub 8 Aug 2020 at 00:45</td>-->
              <!--              </tr>-->
              <!--              <tr>-->

              <!--                <td>-->
              <!--                  <span class="site-status violet">-->
              <!--                    <StatusIcoBtn :type="'received'" />-->
              <!--                    <span>Received</span>-->
              <!--                  </span>-->
              <!--                </td>-->
              <!--                <td>KGood condition. Dormund 22 Aug 2020 at 6:41</td>-->
              <!--              </tr>-->
              <!--              <tr>-->
              <!--                <td>-->
              <!--                  <span class="site-status accent">-->
              <!--                    <StatusIcoBtn :type="'collected'" />-->
              <!--                    <span>Collected</span>-->
              <!--                  </span>-->
              <!--                </td>-->
              <!--                <td>Kiege Euro Hub 8 Aug 2020 at 00:45</td>-->
              <!--              </tr>-->
            </table>
          </div>
        </div>

      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../commonModals/Modal.vue'
import StatusIcoBtn from "../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import {trackingLogMixin} from "@/mixins/trackingLogMixins/trackingLogMixin";

export default {
  name: "StatusOrderDetailsPopup",

  components: {
    Modal,
    StatusIcoBtn,
  },

  mixins: [
    trackingLogMixin,
  ],

  props: {
    trackingLogItem: Object,
  },

  data() {
    return {
      noData: false
    }
  },

  mounted() {
    if (!this.trackingLogItem) {
      this.noData = true
      return
    }
  }


}

</script>

<style scoped>

.ws-initial {
  white-space: initial;
}

.tracking-name {
  position: relative;
  top: 2px;
}

.simple-table th, .simple-table td {
  white-space: initial;
}

.simple-table {
  margin-left: -48px;
  margin-right: -40px;
}

.simple-table__inner th:first-child,
.simple-table__inner td:first-child {
  padding-left: 48px;
}

.simple-table__inner th:last-child,
.simple-table__inner td:last-child {
  padding-right: 40px;
}

.order-details-popup {
  z-index: 999;
}

@media (max-width: 768px) {
  .simple-table {
    margin-left: -15px;
    margin-right: -15px;
  }

  .simple-table__inner th:first-child,
  .simple-table__inner td:first-child {
    padding-left: 15px;
  }

  .simple-table__inner th:last-child,
  .simple-table__inner td:last-child {
    padding-right: 15px;
  }
}

</style>
