<template>
  <div>
    <ParcelsHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :filterGetParams="filterGetParams"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="(data) => $emit('changeFilter', data)"
        @changeTab="(data) => $emit('changeTab', data)"
        @reloadTable="$emit('reloadTable')"
    />

    <div class="table-filter-wrap">

      <ParcelsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          @close="closeFilter"
          @changeFilter="(data) => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ParcelsTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
      />
    </div>
  </div>
</template>

<script>


  import ParcelsHead from "./ParcelsHead/ParcelsHead";
  import ParcelsTable from "./ParcelsTable/ParcelsTable";
  import ParcelsFilter from "./ParcelsFilter/ParcelsFilter";

  export default {
    name: "ParcelsTableUser",

    components: {
      ParcelsHead,
      ParcelsTable,
      ParcelsFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      }
    }
  }
</script>

<style scoped>

</style>


