<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">


      <div class="table-card__item-head-row d-flex mb-2">
        <router-link v-if="navTabs.AllFBM.active || navTabs[GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name].active"
                     :to="getFBMLink(item.order_data.order.order_type, item.order_data.order.order_id)">
                <span class="table-link btn-style">
                  #{{ item.order_data.order.order_id }}
                </span>
        </router-link>
        <router-link v-else
                     :to="$store.getters.GET_PATHS.expressOrders + '/show/' +
                  item.order_data.delivery_service.name.toLowerCase() + '/' + item.order_data.order.order_id">
                <span class="table-link btn-style">
                  #{{ item.order_data.order.order_id }}
                </span>
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          <ValueHelper
              :value="item['created_at']"
              :date="true"
              :dateType="'DD MMM, YYYY'"
          />
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('parcels_Created.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['created_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('parcels_Shipped.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item['delivered_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('parcels_Customer.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'order_data.user.user_full_name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('parcels_TrackingNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div class="table-link btn-style">
                <ValueHelper
                    :value="item"
                    :deep="'tracking_number'"
                />
              </div>
            </div>
          </div>
<!--          <div class="table-card__item-col custom-col">-->
<!--            <div class="table-card__item-label">-->
<!--              {{ $t('parcels_OrderStatus.localization_value.value') }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info d-flex align-items-center">-->
<!--              <StatusIcoBtn-->
<!--                  :type="'delivered'"-->
<!--                  class="mr-1"-->
<!--              />-->
<!--              {{ $t('parcels_Complete.localization_value.value') }}-->
<!--            </div>-->
<!--          </div>-->
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('parcels_DeliveryStatus.localization_value.value') }}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <TableTrackingLog
                  class="mt-1"
                  :trackingLogItem="item"
                  :showCommonTrackingType="true"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click="$emit('deleteItem')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{ $t('common_showMore.localization_value.value') }}
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
import {GLOBAL_DELIVERY_SERVICES} from "@/staticData/staticVariables";

export default {
  name: "ParcelsTableMobile",
  components: {TableTrackingLog,  ValueHelper, LinkButton},

  props: {
    item: Object,
    navTabs: Object,
  },

  data() {
    return {
      show: false,
      GLOBAL_DELIVERY_SERVICES: GLOBAL_DELIVERY_SERVICES,
    }
  },

  methods: {

    getFBMLink(name, id) {
      switch (name) {
        case 'user_create':
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/label/' + id
        case 'sklad_create':
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/warehouse/' + id
        case 'consolidation':
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/consolidation/' + id
      }

    },
  }

}
</script>

<style scoped>

</style>