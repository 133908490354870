import { render, staticRenderFns } from "./ParcelsFilter.vue?vue&type=template&id=a87c27b2&scoped=true"
import script from "./ParcelsFilter.vue?vue&type=script&lang=js"
export * from "./ParcelsFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a87c27b2",
  null
  
)

export default component.exports