<template>
  <div class="fragment">

    <template v-if="$store.getters.getTrackingLog.length > 0 && $store.getters.getTrackingLogLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'parcels_OrderID',
            'parcels_Created',
            'parcels_Shipped',
            'parcels_Customer',
            'parcels_TrackingNumber',
            // 'parcels_OrderStatus',
            'parcels_DeliveryStatus',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{ $t('parcels_OrderID.localization_value.value') }}</th>
            <th v-if="isAdmin">{{ $t('common_user.localization_value.value') }}</th>
            <th>{{ $t('parcels_Created.localization_value.value') }}</th>
            <th>{{ $t('parcels_Shipped.localization_value.value') }}</th>
            <th>{{ $t('parcels_Customer.localization_value.value') }}</th>
            <th>{{ $t('parcels_TrackingNumber.localization_value.value') }}</th>
<!--            <th>{{ $t('parcels_OrderStatus.localization_value.value') }}</th>-->
            <th>{{ $t('parcels_DeliveryStatus.localization_value.value') }}</th>
            <th style="max-width: 180px">{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getTrackingLog"
              :key="index">
            <td>
              <router-link v-if="navTabs.AllFBM.active || navTabs[GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name].active"
                  :to="getFBMLink(item.order_data.order.order_type, item.order_data.order.order_id)">
                <span class="table-link btn-style">
                  #{{ item.order_data.order.order_id }}
                </span>
              </router-link>
              <router-link v-else
                  :to="$store.getters.GET_PATHS.expressOrders + '/show/' +
                  item.order_data.delivery_service.name.toLowerCase() + '/' + item.order_data.order.order_id">
                <span class="table-link btn-style">
                  #{{ item.order_data.order.order_id }}
                </span>
              </router-link>
            </td>
            <td v-if="isAdmin">
<!--              <ValueHelper-->
<!--                  :value="item"-->
<!--                  :deep="'order_data.user.user_full_name'"-->
<!--              />-->

              <TableUserColumn
                  :item="item.order_data"
                  :mobile="true"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item['order_data']['order']['created_at']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item['order_data']['order']['delivery_date']"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'order_data.recipient.recipient_name'"
              />
            </td>
            <td>
              <div class="table-row">
                <div class="table-link btn-style"
                     @click="copyTrackingNumber(item.tracking_number)"
                >
                  <ValueHelper
                      :value="item"
                      :deep="'tracking_number'"
                  />
                </div>
                <!--                <div class="table-ico">-->
                <!--                  <v-popover-->
                <!--                          class="site-tooltip"-->
                <!--                          offset="5"-->
                <!--                          placement="top-left"-->
                <!--                          trigger="hover"-->
                <!--                  >-->
                <!--                      <span class="site-link">-->
                <!--                        <LinkButton-->
                <!--                                :type="'edit'"-->
                <!--                        />-->
                <!--                      </span>-->
                <!--                    <template slot="popover">-->
                <!--                      <div class="section-label mb-2">Tracking Number</div>-->
                <!--                      <InputEditField-->
                <!--                              :value="'39823895799238109235710'"-->
                <!--                      />-->
                <!--                    </template>-->
                <!--                  </v-popover>-->
                <!--                </div>-->
              </div>
            </td>
<!--            <td>-->
<!--              <StatusIcoBtn-->
<!--                  :type="'delivered'"-->
<!--                  class="mr-1"-->
<!--              />-->
<!--              {{ $t('parcels_Complete.localization_value.value') }}-->
<!--            </td>-->
            <td>
              <TableTrackingLog
                  class="mt-1"
                  :trackingLogItem="item"
                  :showCommonTrackingType="true"
              />
              <!--              <StatusIcoBtn :type="'in-transit'" class="mr-2"/>-->
              <!--              In Transit-->
            </td>
            <td style="max-width: 180px">
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="deleteItem(item.id, index)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getTrackingLog"
                 :key="index">
              <ParcelsTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  @deleteItem="deleteItem(item.id, index)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getTrackingLogCommonList.next_page_url !== null && $store.getters.getTrackingLog.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextTrackingLogPage}"
                :count="$store.getters.getTrackingLogCommonList.total - $store.getters.getTrackingLogForPage * $store.getters.getTrackingLogCommonList.current_page < $store.getters.getTrackingLogForPage ?
                  $store.getters.getTrackingLogCommonList.total - $store.getters.getTrackingLogForPage * $store.getters.getTrackingLogCommonList.current_page:
                  $store.getters.getTrackingLogForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getTrackingLogLoading === false && $store.getters.getTrackingLog.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import {GLOBAL_DELIVERY_SERVICES, ORDERS_CONSOLIDATION_TYPE} from "../../../../../../../staticData/staticVariables";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
import ParcelsTableMobile
  from "@/components/modules/ParcelsModule/components/ParcelsTable/ParcelsTableUser/ParcelsTable/ParcelsTableMobile/ParcelsTableMobile";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";


export default {
  name: "ParcelsTable",

  components: {
    TableUserColumn,
    ParcelsTableMobile,
    TableTrackingLog,
    ValueHelper,
    NoResult,
    // MainButton,
    // DefaultInput,
    ShowMore,
    LinkButton,
    ManagerButton,
  },

  mixins: [mixinDetictingMobile],

  props: {
    countFilterParams: Number,
    navTabs: Object,
  },

  data() {
    return {
      orderId: '',
      showFilter: false,
      isMobile: false,

      showContent: false,

      show1: false,
      show2: false,
      show3: false,

      ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
      GLOBAL_DELIVERY_SERVICES: GLOBAL_DELIVERY_SERVICES,
    }
  },


  methods: {

    copyTrackingNumber(number) {
      this.copyLink(number)
      this.openNotify('success', 'common_copied')
    },

    getFBMLink(name, id) {
      switch (name) {
        case 'user_create':
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/label/' + id
        case 'sklad_create':
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/warehouse/' + id
        case 'consolidation':
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/consolidation/' + id
      }

    },

    deleteItem(id, index) {
      let text = {
        title: 'common_deleteConfirm',
        txt: 'common_deleteItemAsk',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteTrackingLog', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            let trackingLog = this.$store.getters.getTrackingLog
            trackingLog.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

  },

}
</script>

<style lang="scss" scoped>

.table-manage-list .manager-btn {
  width: 315px;

  @media (max-width: 1600px) {
    width: 240px;
  }

  @media (max-width: 1430px) {
    width: 150px;
  }
}

.site-table thead th:first-child {
  text-align: center;
}

.site-table .ellipsis {
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
}


</style>
