import {TRACKING_LOG} from "@/staticData/staticVariables";

export const trackingLogMixin = {

  methods: {

    getTypeTrackingLog(log) {
      if (!log || !Object.hasOwnProperty.call(log, 'delivery_status')) return

      let trackingType = ''

      // out-for-delivery
      //     collected
      // received

      switch (log.delivery_status) {
        case TRACKING_LOG.DELIVERED:
          trackingType = 'delivered'
          break
        case TRACKING_LOG.EXCEPTION:
          trackingType = 'no-info'
          break
        case TRACKING_LOG.IN_DELIVERY:
          trackingType = 'in-transit'
          break
        case TRACKING_LOG.IN_RETURN:
          trackingType = 'returned'
          break
        case TRACKING_LOG.IN_TRANSIT:
          trackingType = 'in-transit'
          break
        case TRACKING_LOG.INFO_SENT:
          trackingType = 'in-transit'
          break
        case TRACKING_LOG.NO_INFO:
          trackingType = 'declined'
          break
        case TRACKING_LOG.NOT_FOUND:
          trackingType = 'not_valid'
          break
        case TRACKING_LOG.NOT_VALID:
          trackingType = 'not_valid'
          break
        case TRACKING_LOG.RETURNED:
          trackingType = 'returned'
          break
        case TRACKING_LOG.OUT_FOR_DELIVERY:
          trackingType = 'out-for-delivery'
          break
      }
      let trackingState = this._.last(log?.events)?.tracking_state
      let location = this._.last(log?.events)?.location
      let eventTime = this._.last(log?.events)?.event_time

      return {trackingState, trackingType, location, eventTime}
    },

    getTrackingTypeName(trackingType) {
      let trackingName = ''

      switch (trackingType) {
        case 'delivered':
          trackingName = 'common_delivered'
          break
        case 'in-transit':
          trackingName = 'common_inTransit'
          break
        case 'returned':
          trackingName = 'common_returned'
          break
        case 'no-info':
          trackingName = 'common_exception'
          break
        case 'declined':
          trackingName = 'common_noInfo'
          break
        case 'not_valid':
          trackingName = 'common_notValid'
          break
        case 'out-for-delivery':
          trackingName = 'common_inTransit'
          break
      }

      return trackingName
    },

    getTypeTrackingLogHistory(trackingLogItem) {
      let logs = []

      if(trackingLogItem.events.length === 0){
        return logs
      }


      trackingLogItem.events.map((eventLog) => {
        let log = this.getTypeTrackingLog({
          delivery_status: eventLog?.assigned_delivery_status?.code,
          events: [{tracking_state: eventLog?.tracking_state}]
        })
        log['event_time'] = eventLog.event_time
        log['location'] = eventLog.location
        logs.push(log)
      })

      return logs

    }
  }
}