<template>
  <div class="fragment">
    <div class="white-space-nowrap tracking-log">
      <template v-if="!trackingLogItem && trackingStateEvent">
        <div class="cursor-pointer" v-if="trackingLog = getTypeTrackingLog(trackingStateEvent)"
             @click="showStatusByOrderIdPopup">
          <TooltipTextHelper>
            <template slot="text">
              <StatusIcoBtn :type="trackingLog.trackingType" class="mr-8 mb-2 mt--2"/>
              <template v-if="showCommonTrackingType">
                {{$t(`${getTrackingTypeName(trackingLog.trackingType)}.localization_value.value`)}}
              </template>
            </template>
            <template slot="paragraph">
              <div class="status-tooltip color-black">
                <StatusIcoBtn :type="trackingLog.trackingType" class="mr-8 mt--2"/>
                <!--            {{ trackingLog.trackingState }}-->
                <span class="white-space-nowrap">
                  {{ $t(`${getTrackingTypeName(trackingLog.trackingType)}.localization_value.value`) }}
                </span>
              </div>
            </template>
          </TooltipTextHelper>
        </div>
      </template>

      <template v-else>
        <div class="cursor-pointer" v-if="trackingLog = getTypeTrackingLog(trackingLogItem)"
             @click="showStatusOrderDetailsPopup">
          <TooltipTextHelper>
            <template slot="text">
              <StatusIcoBtn :type="trackingLog.trackingType" class="mr-8 mb-2 mt--2"/>
              <template v-if="showCommonTrackingType">
                {{$t(`${getTrackingTypeName(trackingLog.trackingType)}.localization_value.value`)}}
              </template>
            </template>
            <template slot="paragraph">
              <div class="status-tooltip color-black">
                <StatusIcoBtn :type="trackingLog.trackingType" class="mr-8 mt--2"/>
                <!--            {{ trackingLog.trackingState }}-->
                {{ $t(`${getTrackingTypeName(trackingLog.trackingType)}.localization_value.value`) }}
              </div>
            </template>
          </TooltipTextHelper>
        </div>
      </template>
    </div>


    <StatusOrderDetailsPopup
        v-if="isModalStatusOrderDetailsPopup"
        :trackingLogItem="trackingStateEvent ? trackingLogData : trackingLogItem"
        @close="closeStatusOrderDetailsPopup"
    />

  </div>

</template>

<script>
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";
import {trackingLogMixin} from "@/mixins/trackingLogMixins/trackingLogMixin";
import StatusOrderDetailsPopup
  from "@/components/coreComponents/Popups/StatusOrderDetailsPopup/StatusOrderDetailsPopup";
export default {
  name: "TableTrackingLog",
  components: {StatusOrderDetailsPopup, StatusIcoBtn, TooltipTextHelper},

  mixins: [
    trackingLogMixin,
  ],

  props: {
    trackingLogItem: Object,
    trackingStateEvent: Object,
    // expressId: Number,
    showCommonTrackingType: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      isModalStatusOrderDetailsPopup: false,

      trackingLogData: null
    }
  },

  methods: {

    showStatusOrderDetailsPopup() {
      this.isModalStatusOrderDetailsPopup = true
    },

    closeStatusOrderDetailsPopup() {
      this.isModalStatusOrderDetailsPopup = false
    },

    showStatusByOrderIdPopup() {
      this.$store.dispatch('getTrackingLog', this.trackingStateEvent?.tracking_log_id).then(response => {
        this.trackingLogData = this.getRespData(response)
        this.isModalStatusOrderDetailsPopup = true
      })
    },

    closeStatusByOrderIdPopup() {
      this.trackingLogData = null
      this.isModalStatusOrderDetailsPopup = false
    },
  }

}
</script>

<style scoped>
 .white-space-nowrap.tracking-log >>> .cus-tooltip-popover {
   white-space: nowrap;
 }
</style>