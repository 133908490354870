<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['parcels_TrackingNumber',])"></div>
        <DefaultInput
            :label="$t('parcels_TrackingNumber.localization_value.value')"
            :type="'text'"
            v-model="trackNumber"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['parcels_Created',])"></div>
        <DatePickerDefault
            :label="$t('parcels_Created.localization_value.value')"
            v-model="createdDate"
        >
          <template slot="body">
            <date-picker
                v-model="createdDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['parcels_Shipped',])"></div>
        <DatePickerDefault
            :label="$t('parcels_Shipped.localization_value.value')"
            v-model="deliveredDate"
        >
          <template slot="body">
            <date-picker
                v-model="deliveredDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "ParcelsFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },
    
    mixins: [filterFunc, usersFunc],

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },


    watch: {
      filterGetParams(newVal) {
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.trackNumber = newVal.trackNumber ? newVal.trackNumber : ''
        this.createdDate = newVal.createdDate ? this.generateFilterDate(newVal.createdDate, 'MM/DD/YY') : ''
        this.deliveredDate = newVal.deliveredDate ? this.generateFilterDate(newVal.deliveredDate, 'MM/DD/YY') : ''
        // this.userCountryId = newVal.userCountryId ? newVal.userCountryId : ''
        // this.recipientCountryId = newVal.recipientCountryId ? newVal.recipientCountryId : ''
      },

    },

    data() {
      return {
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        trackNumber: this.filterGetParams.trackNumber ? this.filterGetParams.trackNumber : '',
        createdDate: this.filterGetParams.createdDate ? this.generateFilterDate(this.filterGetParams.createdDate, 'MM/DD/YY') : '',
        deliveredDate: this.filterGetParams.deliveredDate ? this.generateFilterDate(this.filterGetParams.deliveredDate, 'MM/DD/YY') : '',
        // userCountryId: this.filterGetParams.userCountryId ? this.filterGetParams.userCountryId : '',
        // recipientCountryId: this.filterGetParams.recipientCountryId ? this.filterGetParams.recipientCountryId : '',

        filterCounts: [
          'userId',
          'userName',
          'trackNumber',
          'createdDate',
          'deliveredDate',
          // 'userCountryId',
          // 'recipientCountryId',
        ],

        options: [],
        optionsCountries: [],
      }
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.createdDate = this.changeDateParams(this, 'createdDate')
        data.deliveredDate = this.changeDateParams(this, 'deliveredDate')

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
