<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line "
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'parcels_AllOrders',
          'parcels_AllOrdersSub',
          'parcels_DHL',
          'parcels_TNT',
          'parcels_Fedex',
          ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
           v-bind:class="{'loading-tabs': $store.getters.getTrackingLogLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{item.label}}
              </template>
            </div>
            <span class="content-tabs__item-small">
              {{item.subLabel}}
            </span>
          </div>
        </template>
      </div>



<!--        <div class="content-tabs__item" v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--            All Orders-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">DHL, TNT, FedEx</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item" v-bind:class="{active: tab === 2}" @click="changeTab(2)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-dhl.svg" alt="company-ico" width="105">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Warehouse & Your City</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 3}" @click="changeTab(3)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-fedex.svg" alt="company-ico" width="80">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Warehouse & Your City</span>-->
<!--        </div>-->
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 4}" @click="changeTab(4)">-->
<!--          <div class="content-tabs__ico">-->
<!--            <img src="/img/company-icons-group/tab-tnt.svg" alt="company-ico" width="55">-->
<!--          </div>-->
<!--          <span class="content-tabs__item-small">Orders with Courier</span>-->
<!--        </div>-->
<!--      </div>-->

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>
  </div>
</template>

<script>

  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "ParcelsHead",
    components: {
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
      filterGetParams: Object,
    },

    created() {

    },

    data() {
      return {
      }
    },



    methods: {


      changeTab(val) {
        this.$emit('changeTab', val)
      },


    }
  }
</script>

<style scoped>
  @media (min-width: 680px) {
    .btn-left-block{
      flex-wrap: wrap;
      max-width: 50%;
    }

    .btn-left-block-i{
      margin-left: 0!important;
      margin-right: 15px;
    }
  }

  @media (max-width: 680px){
    .btn-right-block{
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .btn-right-block .btn-right-block-i{
      /*margin-top: 15px;*/
      margin-right: 0!important;
    }

    .btn-right-block .btn-right-block-i:last-child{
      margin-top: 0;
    }

    .btn-left-block{
      display: flex;
      flex-direction: column;
      max-width: 50%
    }

    .btn-left-block-i{
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .btn-left-block-i:last-child{
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn-left-block-i--link{
      font-size: 12px;
    }

    .light-bg-block{
      align-items: flex-end;
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
</style>

