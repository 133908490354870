<template>
  <ParcelsTableUser
      :navTabs="navTabs"
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"
      @filter="filter"
      @changeTab="changeTab"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @reloadTable="filter"
  />
</template>

<script>
  import ParcelsTableUser from "./ParcelsTableUser/ParcelsTableUser";
  import {GLOBAL_DELIVERY_SERVICES} from "@/staticData/staticVariables";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ParcelsTable",
    components: {
      ParcelsTableUser,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getTrackingLogForPage,
        page: 1,
        onePage: 1,

        filterType: '',
        filterUserId: '',
        filterUserName: '',
        // filterUserCountryId: '',
        // filterRecipientCountryId: '',
        filterTrackNumber: '',
        filterCreatedDate: [],
        filterDeliveredDate: [],

        countFilterParams: 0,
        filterGetParams: {},

        createData: null,

        navTabs: {
          AllFBM: {
            active: true,
            name: 'AllFBM',
            label: this.$t('parcels_AllOrders.localization_value.value'),
            subLabel: this.$t('parcels_AllOrdersSub.localization_value.value'),
            id: 0,
            ico: false,
            icoWidth: '',
            permissionVisible: false,
          },
          [GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name]: {
            name: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name,
            label: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.label,
            subLabel: this.$t('parcels_DHL.localization_value.value'),
            id: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id,
            ico: GLOBAL_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.ico,
            icoWidth: '105',
            permissionVisible: false,
          },
          // [GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name]: {
          //   active: false,
          //   days: 0,
          //   name: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name,
          //   label: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.label,
          //   subLabel: this.$t('parcels_TNT.localization_value.value'),
          //   id: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id,
          //   ico: GLOBAL_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.ico,
          //   icoWidth: '55',
          //   permissionVisible: false,
          // },
          [GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name]: {
            active: false,
            days: 0,
            name: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name,
            label: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.label,
            subLabel: this.$t('parcels_Fedex.localization_value.value'),
            id: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id,
            ico: GLOBAL_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.ico,
            icoWidth: '80',
            permissionVisible: false,
          },
          [GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name]: {
            active: false,
            days: 0,
            name: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name,
            label: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.label,
            subLabel: this.$t('parcels_Fedex.localization_value.value'),
            id: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.id,
            ico: GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.ico,
            icoWidth: '80',
            permissionVisible: false,
          },
        },
      }
    },


    mounted() {
      if (this.$route.query.type !== undefined && this.navTabs[this.$route.query.type]) {
        this.changeTab(this.navTabs[this.$route.query.type].name)
      } else {
        this.changeTab(this.navTabs[this._.first(Object.keys(this.navTabs))].name)
      }
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          type: this.$route.query.type,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          // userCountryId: this.$route.query.userCountryId,
          // recipientCountryId: this.$route.query.recipientCountryId,
          trackNumber: this.$route.query.trackNumber,
          createdDate: this.$route.query.createdDate,
          deliveredDate: this.$route.query.deliveredDate,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextTrackingLogPage', true)

        // save filter params in store
        this.$store.commit('setTrackingLogFilter', window.location.search)

        this.$store.dispatch('fetchParcelsTwoWeek', url).then(() => {
          // skip get next page
          this.$store.commit('setNextTrackingLogPage', false)
        })

        this.checkCountFilter(['type', 'userName',])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

        this.generateFilterQueryParams(
            myQuery, {
              filterTrackNumber: 'tracking_number',
              filterUserId: 'userId',
              // filterUserCountryId: 'userCountryId',
              // filterRecipientCountryId: 'recipientCountryId',
            },
        )

        if (this.filterCreatedDate.length > 0) {
          let deliveryDate = this.generateDateForFilter(this.filterCreatedDate, 'YYYY-MM-DD')
          myQuery.whereIn('createdAt', deliveryDate)
        }

        if (this.filterDeliveredDate.length > 0) {
          let deliveryDate = this.generateDateForFilter(this.filterDeliveredDate, 'YYYY-MM-DD')
          myQuery.whereIn('shippedAt', deliveryDate)
        }


        if(this.navTabs.AllFBM.active || this.navTabs[GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name].active) {
          myQuery.where('type', 'fbm')


          if (this.navTabs[this.filterType]?.id && this.navTabs[GLOBAL_DELIVERY_SERVICES.APC_DELIVERY_SERVICE.name].active) {
            myQuery.where('delivery_service_id', this.navTabs[this.filterType].id)
          }
        } else {
          if (this.navTabs[this.filterType]?.id) {
            myQuery.where('delivery_service_id', this.navTabs[this.filterType].id)
          }

          myQuery.where('type', 'express')
        }

        myQuery.where('notDelivered14Days', '1')
        // myQuery.where('delivery_status', 'delivered')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },
    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setTrackingLog', {trackingLog: []})
        this.$store.commit('setTrackingLogCommonList', {trackingLogCommonList: []})
      }, 300)
    },

  }
</script>

<style scoped>

</style>
